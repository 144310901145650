<template>
  <div
    :class="{ fs: fullpage, 'no-display': !active }"
    class="loading"
    :style="{ zIndex: zIndex, backgroundColor: bgColor || '#f6f7f8bd', position: position ? position : 'fixed', height: height + 'vh',  minHeight:10 +'vh'}"
  >
    <span>
      <div class="d-flex justify-content-center align-items-center">
        <span class="atbd-spin-dots spin-lg">
          <span class="spin-dot badge-dot dot-primary"></span>
          <span class="spin-dot badge-dot dot-primary"></span>
          <span class="spin-dot badge-dot dot-primary"></span>
          <span class="spin-dot badge-dot dot-primary"></span>
        </span>
        </div>
    </span>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["bgColor", "fullpage", "zIndex", "active", "position", 'height'],
};
</script>

<style scoped>
.fs {
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  
}
.fixed{
    background: #94949421;
    margin: 0 -15px;
    position: fixed;
    width: calc(100% - 280px);
    height: 100%;
    z-index: 9999;
    justify-content: center;
    display: flex;
    align-items: center;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  min-height:50vh;
}
.no-display {
  display: none !important;
}
@media only screen and (max-width: 1150px) {
  .fixed{
    width: 100% !important;
  }
}
</style>
